@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  text-align: center;
  min-height: calc(
    100vh - #{$menu-top-height} - #{$progress-bar-height} - #{$footer-height}
  );
  box-sizing: border-box;
  padding: 64px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title {
  font-size: 10rem;
  background: linear-gradient(
    65.15deg,
    $color-secondary 9.07%,
    $color-primary 89.89%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.description {
  margin-top: 12px;
  margin-bottom: 40px;
}
